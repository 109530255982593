import "./index.scss";
import Form from "../Form";
import Input from "../Form/Input";
import { useContext } from "react";
import Context from "../../Context";
import { Link } from "react-router-dom";

const Newsletter = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="newsletter">
            <div className="container">
                {resumo.textos && <h3 dangerouslySetInnerHTML={{ __html: resumo.textos.newsletter_titulo }}></h3>}
                <Form>
                    <input type="hidden" name="formulario" value="newsletter" />
                    <Input size={5} label={"Digite seu nome"} name={"nome"} type={1} required={1} />
                    <Input size={7} label={"Digite seu e-mail"} name={"email"} type={5} required={1} />
                    <div className="submit">
                        <div className="check">
                            <input type="checkbox" name="lgpd" id="newsletter_lgpd" value="1" required />
                            <label htmlFor="newsletter_lgpd">
                                <span className="checkbox"></span> <span>Li e estou de acordo com os <Link to="/termos-de-uso" title="Termos de uso">termos de uso</Link> e <Link to="/politica-de-privacidade" title="Política de privacidade">política de privacidade</Link>.</span>
                            </label>
                        </div>
                        <button type="submit">Cadastrar</button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Newsletter;
import axios from "axios";
import { useContext } from "react";
import Context from "../../Context";
import "./index.scss";

const Form = (props) => {
    const { setModal } = useContext(Context);
    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        let button = e.target.querySelector("button[type='submit']");
        button.setAttribute("disabled", "disabled");
        axios.post(`${localStorage.getItem("apiUrl")}formularios`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.data.status !== 200) {
                window.alert(response.data.error);
            } else {
                setModal({
                    titulo: "Obrigado!",
                    texto: response.data.success,
                    botao: "Voltar ao site"
                });
                e.target.reset();
            }

            button.removeAttribute("disabled");
        });
    }

    return (
        <form onSubmit={props.handleSubmit ? props.handleSubmit : handleSubmit}>
            {props.children}
        </form>
    );
}

export default Form;
import "./index.scss";
import { formatarTelefone } from "../../../Utils";
import Data from "./cidades.json";
import { useState } from "react";
import Select from "./select.svg";

const Input = (props) => {
    const [cidades, setCidades] = useState([]);
    const [uf, setUf] = useState(false);
    const generatedId = props.name + "-" + (Math.floor(Math.random() * 2000) + 1);

    const fileSelector = (id) => {
        let element = document.getElementById(id);
        element.click();
    }

    const fileSelected = (e) => {
        document.getElementById(`btn-${generatedId}`).innerHTML = e.target.files[0].name;
    }

    const mask = () => {
        if (props.mask && props.mask_split) {
            applyMask(generatedId, props.mask_split, props.mask, props.length ? props.length : 99);
        }
    }

    const applyMask = (id, mask_split, mask, length = 99) => {
        let field = document.getElementById(id);
        let value = field.value.replace(/[^0-9-]/g, "").replace(/(?!^)-/g, "").replace(/(\.|\/|-)/g, "");

        if (value.length > length) {
            value = value.substring(0, length);
        }

        field.value = value.replace(mask_split, mask);
    }

    const changeState = (uf) => {
        setUf(uf);
        let estado = Data.estados.find(x => x.sigla === uf);
        setCidades(estado.cidades);
    }

    return (
        <div className={`form-group s-${props.size}`}>
            {props.type === 1 && <input type="text" name={props.name} id={generatedId} required={props.required} onInput={() => mask()} />}
            {props.type === 2 &&
                <textarea name={props.name} id={generatedId} required={props.required}></textarea>
            }
            {props.type === 3 && <input type="text" name={props.name} id={generatedId} required={props.required} onInput={(e) => { e.target.value = formatarTelefone(e.target.value) }} />}
            {props.type === 4 && <input type="number" name={props.name} id={generatedId} required={props.required} />}
            {props.type === 5 && <input type="email" name={props.name} id={generatedId} required={props.required} />}
            {props.type === 6 &&
                <>
                    <img src={Select} alt="arrow" className="arrow" />
                    <select name={props.name} id={generatedId} required={props.required} onChange={props.onChange}>
                        <option value="">Selecione uma opção</option>
                        {props.opcoes.map((opcao, i) => <option value={opcao} key={`opcao-${props.name}-${i}`}>{opcao}</option>)}
                    </select>
                </>
            }
            {props.type === 7 &&
                <>
                    <button type="button" id={`btn-${generatedId}`} className="file-uploader" htmlFor={props.name} onClick={() => fileSelector(generatedId)}>Inserir arquivo</button>
                    <input type="file" name={props.name} id={generatedId} required={props.required} onChange={(e) => fileSelected(e)} />
                </>
            }
            {props.type === 8 &&
                <div className="cidade">
                    <div className="uf">
                        <img src={Select} alt="arrow" className="arrow" />
                        <select name="uf" onChange={(e) => changeState(e.target.value)} required={props.required}>
                            <option value="">UF</option>
                            {Data.estados.map(estado => <option key={`${generatedId}-${estado.sigla}`} value={estado.sigla} selected={estado.sigla === uf}>{estado.sigla}</option>)}
                        </select>
                    </div>
                    <div>
                        <img src={Select} alt="arrow" className="arrow" />
                        <select name={props.name} id={generatedId} required={props.required}>
                            {cidades.length < 1 && <option value="">Selecione o estado</option>}
                            {cidades.length >= 1 && <option value="">Selecione a cidade</option>}
                            {cidades.map((cidade, i) => <option key={`${generatedId}-${i}`} value={cidade}>{cidade}</option>)}
                        </select>
                    </div>
                </div>
            }
            <label htmlFor={generatedId}>{props.label}</label>
        </div>
    )
}

export default Input;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MDSZKLG'
}

let checkCookies = setInterval(() => {
  let accept = localStorage.getItem("fw2AcceptCookiesDesnecessarios");
  if (accept) {
    TagManager.initialize(tagManagerArgs)
    clearInterval(checkCookies);
  }
}, 3000);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useContext, useState, useEffect } from "react";
import Context from "../../Context";
import Facebook from "../../Assets/images/icons/facebook.png";
import Instagram from "../../Assets/images/icons/instagram.png";
import Linkedin from "../../Assets/images/icons/linkedin.png";
import Menu from "../../Assets/images/icons/menu.svg";
import Pt from "../../Assets/images/icons/pt.png";
import En from "../../Assets/images/icons/en.png";
import Es from "../../Assets/images/icons/es.png";
import Close from "../../Assets/images/icons/close.svg";
import { Link } from "react-router-dom";
import "./index.scss";

const Header = () => {
    const { resumo } = useContext(Context);
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        let script = document.createElement("script");
        script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.type = "text/javascript";
        document.body.appendChild(script);
    }, []);

    return (
        <div id="header">
            <div className="container">
                <div className="topbar">
                    <div>
                        <div id="google_translate_element"></div>
                        <p>Casa limpa e renovada natureza preservada!</p>
                    </div>
                    {resumo.configuracoes &&
                        <div>
                            <button className="active"><img src={Pt} alt="Português" /></button>
                            <button className="active"><img src={En} alt="Inglês" /></button>
                            <button className="active"><img src={Es} alt="Espanhol" /></button>
                            <p>{resumo.configuracoes.telefone}</p>
                            {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} target="_blank" title="Facebook" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>}
                            {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} target="_blank" title="Instagram" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>}
                            {resumo.configuracoes.linkedin.length > 5 && <a href={resumo.configuracoes.linkedin} target="_blank" title="Linkedin" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin" /></a>}
                        </div>
                    }
                </div>
                <div className="header">
                    {resumo.imagens && <Link to="/" title="Duplás"><img src={resumo.imagens.logo} alt="Duplás" /></Link>}
                    <button type="button" className={`toggleMenu ${menu ? "active" : ""}`} onClick={() => setMenu(!menu)}>
                        {!menu && <img src={Menu} alt="Menu" title="Menu" />}
                        {menu && <img src={Close} alt="Menu" title="Menu" />}
                    </button>
                    <div className={`menu ${menu ? "active" : ""}`} >
                        <Link to="/" title="Home" translate="no">Home</Link>
                        <Link to="/empresa" title="Empresa">Empresa</Link>
                        <Link to="/sustentabilidade" title="Sustentabilidade">Sustentabilidade</Link>
                        {resumo.categorias && <Link to={`/categoria/${resumo.categorias[0].slug}`} title="Produtos">Produtos</Link>}
                        <Link to="/blog" title="Blog">Blog</Link>
                        <Link to="/parceiro" title="Seja nosso parceiro">Seja nosso parceiro</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
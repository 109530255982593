function formatarTelefone(telefone) {
    telefone = numbersOnly(telefone).replace(/(\.|\/|-)/g, "");

    if (telefone.length > 11) {
        telefone = telefone.substring(0, 11);
    }

    if (telefone.length <= 10) {
        return telefone.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
    } else {
        return telefone.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
    }
}

function scrollToSmoothly(pos, time) {
    var currentPos = window.pageYOffset;
    var start = null;
    if (time == null) time = 500;
    window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
            window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        } else {
            window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        }
        if (progress < time) {
            window.requestAnimationFrame(step);
        } else {
            window.scrollTo(0, pos);
        }
    });
}

function formatarCnpj(cnpj) {
    cnpj = numbersOnly(cnpj).replace(/(\.|\/|-)/g, "");

    if (cnpj.length > 14) {
        cnpj = cnpj.substring(0, 14);
    }
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}

function formatarCep(cep) {
    cep = numbersOnly(cep).replace(/(\.|\/|-)/g, "");

    if (cep.length > 8) {
        cep = cep.substring(0, 8);
    }

    return cep.replace(/(\d{5})(\d{3})/g, "$1-$2");
}

const handleCEPChange = async (e) => {
    let cep = e.target.value.replace(/(\.|\/|-)/g, "");
    if (cep.length >= 8) {
        cep = cep.substring(0, 8);
    } else {
        return false;
    }
    e.target.value = cep.replace(/(\d{5})(\d{3})/g, "$1-$2");
    const response = await fetch("https://viacep.com.br/ws/" + cep + "/json/");
    const data = await response.json();
    let ruaElement = document.querySelector("input[name='rua']");
    let bairroElement = document.querySelector("input[name='bairro']");
    let complementoElement = document.querySelector("input[name='complemento']");
    let cidadeElement = document.querySelector("input[name='cidade']");
    let estadoElement = document.querySelector("input[name='estado']");

    ruaElement.setAttribute("readonly", "true");
    bairroElement.setAttribute("readonly", "true");
    cidadeElement.setAttribute("readonly", "true");
    estadoElement.setAttribute("readonly", "true");

    if (data.erro) {
        ruaElement.removeAttribute("readonly");
        bairroElement.removeAttribute("readonly");
        cidadeElement.removeAttribute("readonly");
        estadoElement.removeAttribute("readonly");
    } else {
        ruaElement.value = data.logradouro;
        bairroElement.value = data.bairro;
        complementoElement.value = data.complemento;
        estadoElement.value = data.uf;
        cidadeElement.value = data.localidade;

        if (data.logradouro.length < 5) {
            ruaElement.removeAttribute("readonly");
            bairroElement.removeAttribute("readonly");
            cidadeElement.removeAttribute("readonly");
            estadoElement.removeAttribute("readonly");
        }
    }
}

function numbersOnly(e) {
    return e.replace(/[^0-9-]/g, "").replace(/(?!^)-/g, "");
}

function string_to_slug(str) {
    str = str.toLowerCase();
    str = str.replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
        .replace(/[èÈéÉêÊëË]+/g, 'e')       	// Special Characters #2
        .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       	// Special Characters #3
        .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')       	// Special Characters #4
        .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       	// Special Characters #5
        .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
        .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
        .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
        .replace(/[ß]+/g, 'ss')       			// Special Characters #9
        .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
        .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
        .replace(/[%]+/g, 'pct')       			// Special Characters #12
        .replace(/\s+/g, '-')           		// Replace spaces with -
        .replace(/[^\w-]+/g, '')       		// Remove all non-word chars
        .replace(/--+/g, '-')         		// Replace multiple - with single -
        .replace(/^-+/, '')             		// Trim - from start of text
        .replace(/-+$/, '');            		// Trim - from end of text

    return str;
}

function gerarCaptcha() {
    let code = "";
    //clear the contents of captcha div first
    document.getElementById("captcha").innerHTML = "";
    let words = [
        "digital",
        "dental",
        "dente",
        "tecnologia",
        "produto",
        "resina",
        "qualidade",
        "vitalidade",
        "dinamismo",
        "sorriso",
        "harmonia"
    ];
    let word = words[Math.floor(Math.random() * ((words.length - 1) + 1))];
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 30;
    var ctx = canv.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(50, 0);
    ctx.stroke();
    ctx.font = "16px Georgia";
    ctx.textAlign = 'center';
    ctx.strokeText(word, 50, 15);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    code = word;
    document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element

    return code;
}

function validarCaptcha(code) {
    if (document.getElementById("captchaTextbox").value === code) {
        return true;
    } else {
        return false;
    }
}

function number_format(number, decimals, dec_point = ",", thousands_sep = ".") {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function integer_to_price(number, decimals = 2, dec_point = ",", thousands_sep = ".") {
    // Strip all characters but numerical ones.
    number = number / 100;
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

const TTspeech = (text) => {
    // Create a new instance of SpeechSynthesisUtterance.
    var msg = new SpeechSynthesisUtterance();

    // Set the text.
    msg.text = text;

    // Set the attributes.
    msg.volume = parseFloat(1);
    msg.rate = parseFloat(0.3);
    msg.pitch = parseFloat(1);

    // If a voice has been selected, find the voice and set the
    // utterance instance's voice attribute.
    msg.voice = speechSynthesis.getVoices()[0];

    // Queue this utterance.
    window.speechSynthesis.speak(msg);
}

export {
    formatarCep,
    handleCEPChange,
    formatarTelefone,
    formatarCnpj,
    string_to_slug,
    gerarCaptcha,
    validarCaptcha,
    numbersOnly,
    number_format,
    TTspeech,
    integer_to_price,
    scrollToSmoothly
}
import "./index.scss";
import { useContext } from "react";
import Context from "../../Context";
import Newsletter from "../Newsletter";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook-w.png";
import Instagram from "../../Assets/images/icons/instagram-w.png";
import Linkedin from "../../Assets/images/icons/linkedin-w.png";
import Logo from "../../Assets/images/logo-branco.svg";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <Newsletter />
            <div className="footer">
                <div className="container">
                    <div>
                        <Link to="/" title="Duplás"><img src={Logo} alt="Duplás" /></Link>
                        {resumo.configuracoes &&
                            <p>
                                {resumo.configuracoes.endereco} - {resumo.configuracoes.bairro}<br /> {resumo.configuracoes.cidade} - {resumo.configuracoes.estado}, {resumo.configuracoes.cep}
                            </p>
                        }
                        {resumo.configuracoes &&
                            <div className="socials">
                                {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} target="_blank" title="Facebook" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>}
                                {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} target="_blank" title="Instagram" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>}
                                {resumo.configuracoes.linkedin.length > 5 && <a href={resumo.configuracoes.linkedin} target="_blank" title="Linkedin" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin" /></a>}
                            </div>
                        }
                    </div>
                    <div>
                        <h4>A Duplás</h4>
                        <div className="menu">
                            <Link to="/empresa" title="Empresa">Empresa</Link>
                            <Link to="/sustentabilidade" title="Sustentabilidade">Sustentabilidade</Link>
                            <Link to="/internacional" title="Exportação">Exportação</Link>
                            <Link to="/parceiro" title="Seja nosso parceiro">Seja nosso parceiro</Link>
                            <Link to="/blog" title="Blog">Nosso blog</Link>
                        </div>
                    </div>
                    <div>
                        <h4>Institucional</h4>
                        <div className="menu">
                            <Link to="/contato" title="Fale conosco">Fale conosco</Link>
                            <Link to="/trabalhe-conosco" title="Trabalhe conosco">Trabalhe conosco</Link>
                            <Link to="/politica-de-privacidade" title="Política de privacidade">Política de privacidade</Link>
                            <Link to="/termos-de-uso" title="Termos de uso">Termos de uso</Link>
                        </div>
                    </div>
                    <div>
                        <h4>Produtos</h4>
                        <div className="menu">
                            {resumo.categorias.map((categoria, i) => { return i < 5 ? <Link key={`footer-cats-${categoria.id}`} to={`/categoria/${categoria.slug}`} title={categoria.nome}>{categoria.nome}</Link> : false })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="credits">
                <div className="container">
                    <p>Duplás - Indústria de peças plásticas EIRELI | 08.740.765/0001-70</p>
                    <p>Desenvolvido por <a href="https://fw2propaganda.com.br/" target="_blank" title="Desenvolvido por Gustavo Barbosa" rel="noopener noreferrer">FW2 Propaganda</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer;
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, setMenuOpen, menuOpen }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      if (menuOpen) {
        setMenuOpen(false);
      }
    });
    return () => {
      unlisten();
    }
  }, [history, menuOpen, setMenuOpen]);

  return (null);
}

export default withRouter(ScrollToTop);